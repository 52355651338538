/* eslint-disable @next/next/no-css-tags */
import dynamic from "next/dynamic";
import { GetServerSidePropsContext, NextPage } from "next";
import Head from "next/head";
import getSettings from "utils/getSettings";

const Login = dynamic(() => import("admin/src/components/Login"));

const Home: NextPage = () => {
    return (
        <>
            <Head>
                <title>Login</title>
                <link media="all" rel="stylesheet" href="/static/styles/admin/login.css" />
            </Head>
            <Login />
        </>
    );
};

export const getServerSideProps = async ({
    res,
    req,
}: GetServerSidePropsContext) => {

    res.setHeader(
        "Cache-Control",
        "public, s-maxage=3600, stale-while-revalidate=300"
    );
    const { settings } = await getSettings(req);

    return {
        props: {
            settings,
            page: 'login',
        },
    };
};

export default Home;
